import { type ProjectViewMode, projectViewModes, projectsSortOptions } from '@training/constants'
import { round } from 'lodash'
import { create } from 'zustand'
import { devtools, persist, createJSONStorage } from 'zustand/middleware'

interface VideoClipMetadata {
  videoFileId?: string
  status?: string
}

export interface TrainingProgressMetadata {
  projectId?: string
  totalSteps?: number
  completedSteps?: number
  trainingProgress?: number
}

interface AppStateStore {
  confidenceLevelValue: number | null
  setConfidenceLevelValue: (confidenceLevelValue: number | null) => void
  isStartingTraining: boolean
  setIsStartingTraining: (isStartingTraining: boolean) => void
  comparePlayerEnabled: boolean
  setComparePlayerEnabled: (comparePlayerEnabled: boolean) => void
  projectsSearchQuery: string
  setProjectsSearchQuery: (projectsSearchQuery: string) => void
  projectsSortingOption: (typeof projectsSortOptions)[number]['id']
  setProjectsSortingOption: (
    projectsSortingOption: (typeof projectsSortOptions)[number]['id']
  ) => void
  projectViewMode: ProjectViewMode
  setProjectViewMode: (projectViewMode: ProjectViewMode) => void
  suggestedFramesClipsProgress: number
  setsuggestedFramesClipsProgress: (suggestedFramesClipsProgress: number) => void
  videoClipMetadata: VideoClipMetadata
  setVideoClipMetadata: (videoClipMetadata: VideoClipMetadata) => void
  setIsHeaderBannerOpen: (isHeaderBannerOpen: boolean) => void
  isHeaderBannerOpen: boolean
  setTrainingProgresses: (trainingProgressMetadata: TrainingProgressMetadata) => void
  trainingProgresses: TrainingProgressMetadata[]
}

export const useAppState = create<AppStateStore>()(
  persist(
    devtools((set) => ({
      isStartingTraining: false,
      setIsStartingTraining: (isStartingTraining) => set({ isStartingTraining }),
      setComparePlayerEnabled: (comparePlayerEnabled) => set({ comparePlayerEnabled }),
      comparePlayerEnabled: false,
      setProjectsSortingOption: (projectsSortingOption) => set({ projectsSortingOption }),
      projectsSortingOption: '1',
      projectsSearchQuery: '',
      setProjectsSearchQuery: (projectsSearchQuery) => set({ projectsSearchQuery }),
      confidenceLevelValue: null,
      setConfidenceLevelValue: (confidenceLevelValue) => set({ confidenceLevelValue }),
      projectViewMode: projectViewModes.INITIAL,
      setProjectViewMode: (projectViewMode) => set({ projectViewMode }),
      suggestedFramesClipsProgress: 0,
      setsuggestedFramesClipsProgress: (suggestedFramesClipsProgress) =>
        set({ suggestedFramesClipsProgress }),
      videoClipMetadata: {},
      setVideoClipMetadata: (videoClipMetadata) => set({ videoClipMetadata }),
      setIsHeaderBannerOpen: (isHeaderBannerOpen) => set({ isHeaderBannerOpen }),
      isHeaderBannerOpen: false,
      setTrainingProgresses: (trainingProgressMetadata: TrainingProgressMetadata) => {
        set((state) => {
          const updateProgressIndex = state.trainingProgresses.findIndex(
            (tp) => tp.projectId === trainingProgressMetadata.projectId
          )

          if (updateProgressIndex !== -1) {
            return {
              trainingProgresses: [
                ...state.trainingProgresses.slice(0, updateProgressIndex),
                {
                  ...state.trainingProgresses[updateProgressIndex],
                  trainingProgress: round(
                    (100 * (trainingProgressMetadata.completedSteps ?? 0)) /
                      (trainingProgressMetadata.totalSteps ?? 0)
                  ),
                },
                ...state.trainingProgresses.slice(updateProgressIndex + 1),
              ],
            }
          }
          return {
            trainingProgresses: [
              ...state.trainingProgresses,
              {
                ...trainingProgressMetadata,
                trainingProgress: round(
                  (100 * (trainingProgressMetadata.completedSteps ?? 0)) /
                    (trainingProgressMetadata.totalSteps ?? 0)
                ),
              },
            ],
          }
        })
      },
      trainingProgresses: [],
    })),
    {
      name: 'app-state',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        projectsSortingOption: state.projectsSortingOption,
      }),
    }
  )
)
