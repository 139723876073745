/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useMemo } from 'react'

import { createJSONStorage, devtools, persist, type StateStorage } from 'zustand/middleware'
import { create } from 'zustand'
import { envVars } from '@training/constants'
import { useGetFeatureFlags } from '@training/apis/feature-flags-service/requests'
import { isEmpty, noop, reduce } from 'lodash'
import type { FeatureFlags } from '@training/apis/types'
import { useMinimeState } from './useMinimeState'

export type FeatureFlagsMap = Record<FeatureFlags | (string & {}), boolean | undefined>

interface FeatureFlagsStore {
  featureFlags: FeatureFlagsMap
  toggleFeatureFlag: (name: keyof FeatureFlagsMap) => void
  setFeatureFlags: (flags: FeatureFlagsMap) => void
}

/**
 * Shouldn't be used directly, use `useFeatureFlags` instead
 */
export const useFeatureFlagsState = create<FeatureFlagsStore>()(
  devtools(
    persist(
      (set) => ({
        featureFlags: {} as FeatureFlagsMap,
        setFeatureFlags: (flags) => {
          set(() => ({ featureFlags: flags }))
        },
        toggleFeatureFlag: (name) => {
          set((state) => {
            const newValue = !state.featureFlags[name]
            return {
              featureFlags: {
                ...state.featureFlags,
                [name]: newValue,
              },
            }
          })
        },
      }),
      {
        name: 'ff-state',
        storage: createJSONStorage(() => ({ setItem: noop, removeItem: noop }) as StateStorage),
        skipHydration: true,
      }
    ),
    {
      enabled: envVars.IS_DEVELOPMENT,
    }
  )
)

/**
 * Hook to get the feature flags state from the api and persist it in the session storage if the persist feature flag is enabled
 * @returns The feature flags state
 */
export const useFeatureFlags = () => {
  const orgId = useMinimeState((state) => state.orgId)

  const { data: features } = useGetFeatureFlags(orgId)

  const setFeatureFlags = useFeatureFlagsState((state) => state.setFeatureFlags)
  const featureFlagsState = useFeatureFlagsState((state) => state.featureFlags)

  const featureFlagsMap = useMemo(
    () =>
      features && Array.isArray(features) && features.length > 0
        ? reduce(
            features,
            (acc, feature) => {
              return {
                ...acc,
                [feature.name]: feature.organizations?.[0]?.enabled ?? feature.enabled,
              }
            },
            {} as FeatureFlagsMap
          )
        : ({} as FeatureFlagsMap),
    [features]
  )

  const isStateInitialized = !isEmpty(featureFlagsState)

  useEffect(() => {
    if (
      !isStateInitialized &&
      !isEmpty(featureFlagsMap) &&
      !useFeatureFlagsState.persist.hasHydrated()
    ) {
      setFeatureFlags(featureFlagsMap)
    }

    if (featureFlagsMap.DEV_FF_MANAGEMENT) {
      useFeatureFlagsState.persist.setOptions({
        storage: createJSONStorage(() => sessionStorage),
      })
      // rehydrate the state to override the api response
      useFeatureFlagsState.persist.rehydrate()
    }
  }, [featureFlagsMap, setFeatureFlags, isStateInitialized])

  return featureFlagsState
}
