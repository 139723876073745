type StorageType = 'session' | 'local'
type ValueType = string | null

export const storage = (
  storageType?: StorageType
): {
  setItem: (key: string, value: ValueType) => void
  getItem: (key: string) => ValueType
  removeItem: (key: string) => void
} => {
  const internalStorage = storageType === 'local' ? window.localStorage : window.sessionStorage

  const setItem = (key: string, value: ValueType) => {
    internalStorage.setItem(key, JSON.stringify(value))
  }

  const getItem = (key: string): ValueType => {
    const item = internalStorage.getItem(key)
    if (item) {
      return JSON.parse(item)
    }
    return null
  }

  const removeItem = (key: string) => {
    internalStorage.removeItem(key)
  }

  return { setItem, getItem, removeItem }
}
