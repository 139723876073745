import { storageKeys, urlParams } from '@training/constants'
import { getUrlParam } from '@training/utils/getUrlParam'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { storage } from '../utils/storage'

export const themes = {
  DARK: 'dark',
  LIGHT: 'light',
  SYSTEM: 'system',
} as const

export type Theme = (typeof themes)[keyof typeof themes]

interface MinimeStore {
  userName: string
  firstName: string
  lastName: string
  userId: string
  theme: Theme
  setUser: (user: { userName: string; firstName: string; lastName: string; userId: string }) => void
  setTheme: (value: Theme) => void
  orgId: string
  setOrgId: (orgId: string) => void
  orgName: string
  setOrgName: (orgName: string) => void
}

const initialOrgId = getUrlParam(urlParams.ORG_ID) || storage().getItem(storageKeys.ORG_ID) || ''

export const useMinimeState = create<MinimeStore>()(
  persist(
    (set) => ({
      userName: '',
      firstName: '',
      lastName: '',
      userId: '',
      theme: themes.DARK,
      setUser: (user) => set((state) => ({ ...state, ...user })),
      setTheme: (value) => set({ theme: value }),
      orgId: initialOrgId,
      orgName: '',
      setOrgName: (orgName) => set({ orgName }),
      setOrgId: (orgId) => set({ orgId }),
    }),
    {
      name: 'minime-state',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
