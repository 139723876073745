import { MainLayout } from '@training/layouts/MainLayout'
import License from '@training/pages/License/License'
import { AppProvider } from '@training/providers/AppProvider'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { queryClient } from '@training/apis/query-client'
import { licenseLoader, organizationLoader } from './loaders'

export const router = createBrowserRouter([
  {
    path: '/',
    loader: organizationLoader,
    element: (
      <AppProvider>
        <MainLayout />
      </AppProvider>
    ),
    children: [
      {
        path: '/',
        index: true,
        element: <Navigate to='training' replace />,
      },
      {
        path: '/license',
        element: <License />,
      },
      {
        path: '/training/*',
        loader: licenseLoader(queryClient),
        async lazy() {
          const { SubscriptionRouteProvider } = await import(
            '@training/providers/SubscriptionRouteProvider'
          )
          return {
            Component: SubscriptionRouteProvider,
          }
        },
        children: [
          {
            path: '',
            async lazy() {
              const { ProjectsPage } = await import('@training/pages/Projects')
              return {
                Component: ProjectsPage,
              }
            },
          },
          {
            path: 'project/:id',
            async lazy() {
              const { ProjectPage } = await import('@training/pages/Project')
              return {
                Component: ProjectPage,
              }
            },
          },
          {
            path: 'settings',
            async lazy() {
              const { SettingsPage } = await import('@training/pages/Settings')
              return {
                Component: SettingsPage,
              }
            },
            children: [
              {
                path: '',
                index: true,
                element: <Navigate to='flex-ai-licenses' replace />,
              },
              {
                path: 'flex-ai-licenses',
                async lazy() {
                  const { FlexAILicenses } = await import('@training/pages/Settings/FlexAILicenses')
                  return {
                    Component: FlexAILicenses,
                  }
                },
              },
              {
                path: 'application-limitations',
                async lazy() {
                  const { ApplicationLimitations } = await import(
                    '@training/pages/Settings/ApplicationLimitations'
                  )
                  return {
                    Component: ApplicationLimitations,
                  }
                },
              },
              {
                path: 'software-info',
                async lazy() {
                  const { SoftwareInfo } = await import('@training/pages/Settings/SoftwareInfo')
                  return {
                    Component: SoftwareInfo,
                  }
                },
              },
            ],
          },
          {
            path: 'devices',
            async lazy() {
              const { DevicePage } = await import('@training/pages/Device')
              return {
                Component: DevicePage,
              }
            },
          },
        ],
      },
    ],
  },
])
