import { Icon, Typography } from '@htaic/cue'
import { headerBannerTypes } from '@training/constants'
import { useAppState } from '@training/store/useAppState'
import { memo, useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface HeaderBannerProps {
  type: keyof typeof headerBannerTypes
  open: boolean
  onClose?: () => void
  children: string
  className?: string
}

export const HeaderBanner = memo((props: HeaderBannerProps) => {
  const { type, open, onClose, children, className, ...rest } = props

  const setIsHeaderBannerOpen = useAppState((state) => state.setIsHeaderBannerOpen)

  const [isOpen, setIsOpen] = useState(open)

  const onCloseHandler = useCallback(() => {
    setIsOpen(false)
    onClose?.()
  }, [onClose])

  useEffect(() => {
    setIsHeaderBannerOpen(isOpen)
  })

  return isOpen ? (
    <div
      className={twMerge(
        'flex justify-center items-center w-full min-h-9 py-2 px-4',
        headerBannerTypes[type],
        className
      )}
    >
      <Typography
        variant='body2'
        {...rest}
        className='max-w-[90%] font-medium text-hv-neutral-19 dark:text-hv-semantic-primary'
      >
        {children}
      </Typography>
      <div className='absolute right-5'>
        <Icon
          data-testid='close-header-banner'
          name='Close'
          size='xsmall'
          className='stroke-2 hover:cursor-pointer font-medium text-hv-neutral-19 dark:text-hv-semantic-primary hover:text-hv-neutral-10'
          onClick={onCloseHandler}
        />
      </div>
    </div>
  ) : null
})

HeaderBanner.displayName = 'HeaderBanner'
