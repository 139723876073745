import { Icon, Popover } from '@htaic/cue'
import type React from 'react'
import { twMerge } from 'tailwind-merge'

export interface HelpCardProps {
  children?: React.ReactNode
}

export interface HelpCardTriggerProps {
  className?: string
}

const HelpCardTrigger = (props: HelpCardTriggerProps) => {
  const { className, ...rest } = props

  return (
    <Popover.Trigger
      data-testid='help-card-trigger-button'
      className={twMerge(
        'rounded-full p-1 text-hv-neutral-4 dark:text-hv-neutral-3',
        'hover:bg-hv-neutral-18 dark:hover:bg-hv-neutral-17 ',
        className
      )}
      {...rest}
    >
      <Icon name='Help' size='large' />
    </Popover.Trigger>
  )
}

export type HelpLinkProps = {
  iconLeft: React.ReactNode
  iconRight: React.ReactNode
  url: string
  label: string
}

export interface HelpCardPopperProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Popover.Container>, 'open' | 'anchorEl'> {
  helpLinks: HelpLinkProps[]
  onHelpClick?: () => void
}

const HelpCardPopper = (props: HelpCardPopperProps) => {
  const { helpLinks, onHelpClick, className, ...rest } = props

  return (
    <Popover.Container
      className={twMerge(
        'p-0 border border-solid border-hv-border-default dark:border-hv-neutral-14',
        className
      )}
      {...rest}
    >
      <div className='divide-y divide-solid divide-neutral-grey-14 dark:divide-neutral-grey-11'>
        {helpLinks ? (
          <div className='p-4'>
            {helpLinks.map((link) => (
              <a
                key={link.url}
                href={link.url}
                onClick={onHelpClick}
                className={twMerge(
                  'no-underline text-hv-neutral-7 flex items-center justify-between w-[240px] h-[40px] px-2 rounded',
                  'hover:bg-hv-neutral-18 active:bg-hv-neutral-17',
                  'dark:hover:bg-hv-neutral-14 dark:active:bg-hv-neutral-16 dark:text-hv-neutral-0'
                )}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='flex items-center'>
                  {link.iconLeft ?? null}
                  {link.label}
                </div>
                {link.iconRight ?? null}
              </a>
            ))}
          </div>
        ) : null}
      </div>
    </Popover.Container>
  )
}

const HelpCard = ({ children }: HelpCardProps) => <Popover>{children}</Popover>

HelpCard.Trigger = HelpCardTrigger
HelpCard.Container = HelpCardPopper
HelpCard.Popper = HelpCardPopper

export default HelpCard
