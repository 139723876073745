export interface LicenseStatus {
  isExpired: boolean
  storageExceeded: boolean
  processesExceeded: boolean
  projectsExceeded: boolean
}

type LicenseKey = keyof LicenseStatus

export const getLicensePlanMessage = (licenseStatus: LicenseStatus) => {
  let message = ''

  const formatList = new Intl.ListFormat('en')

  // Check if the FLEXAI license has expired
  const expiredLicenses = licenseStatus.isExpired

  // Check if any limits reached
  const limitsReached = (Object.keys(licenseStatus) as LicenseKey[]).filter((key) => {
    if (key === 'isExpired') return false

    return !!licenseStatus[key]
  })

  if (!expiredLicenses && limitsReached.length === 0) {
    return ''
  }

  if (expiredLicenses) {
    message += 'Your FLEX AI license has expired. '
  }

  if (limitsReached.length > 0) {
    message += 'You have reached your limit for '
  }

  const limitKeys = limitsReached.map((key) => {
    if (key === 'processesExceeded') return 'trainings'
    return key.replace('Exceeded', '')
  })

  message += `${formatList.format(limitKeys)}${limitsReached.length > 0 ? '. ' : ''}`

  message += 'Go to your FLEX AI licenses or contact your System Integrator (SI) to add more.'

  return message
}
