import { useMemo, useCallback } from 'react'

export const useTestingHelper = (componentId: string) => {
  const getTestIdProps = useCallback(
    (id: string, ariaLabel?: string) => {
      const testId = `${componentId}-${id}`
      const accessibilityLabel = ariaLabel ?? testId.split('-').join(' ')
      return { 'data-testid': testId, 'aria-label': accessibilityLabel }
    },
    [componentId]
  )
  return useMemo(() => ({ getTestIdProps }), [getTestIdProps])
}
