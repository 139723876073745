import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { endpoints } from '@training/constants'
import { FeatureFlag } from '@training/apis/types'
import { useGetLicenseStatus } from '../licenses/requests'

export const featureFlagsApiUrl = endpoints.VITE_FEATURE_FLAGS_API ?? 'http://localhost:3020'

/**
 * @returns the list of feature flags
 */
export function useGetFeatureFlags(orgId: string) {
  const licenseStatus = useGetLicenseStatus(orgId)

  const getData = async () => {
    const { data } = await axios.get<FeatureFlag[]>(`${featureFlagsApiUrl}/feature-flags/v1`, {
      params: { organizationId: orgId },
    })
    return data
  }
  return useQuery({
    queryKey: ['getFeatureFlags'],
    queryFn: () => getData(),
    enabled: !!orgId && licenseStatus.data === 'OK',
  })
}

/**
 * @param id - the feature flag id
 * @returns
 */
export function useGetFeatureFlag(id: string) {
  const getData = async () => {
    const { data } = await axios.get<FeatureFlag>(`${featureFlagsApiUrl}/feature-flags/v1/${id}`)
    return data
  }
  return useQuery({ queryKey: ['getFeatureFlag', id], queryFn: () => getData() })
}
