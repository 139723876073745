import { ClickAwayListener, IconButton } from '@mui/material'
import { Icon, Popper as PopperComponent } from '@htaic/cue'
import { useTestingHelper } from '@training/hooks/useTestingHelper'
import React from 'react'
import { twMerge } from 'tailwind-merge'

export interface PopperProps {
  id: string
  content: React.ReactNode
  anchorEl: React.ComponentProps<typeof PopperComponent>['anchorEl']
  placement?: React.ComponentProps<typeof PopperComponent>['placement']
  closable?: boolean
  onClose?: () => void
  className?: string
}
export const Popper = ({
  id,
  anchorEl,
  closable = false,
  content,
  onClose,
  className,
  placement = 'right',
}: PopperProps) => {
  const { getTestIdProps } = useTestingHelper('popover')

  if (!anchorEl) return null

  return (
    <PopperComponent
      open
      id={id}
      anchorEl={anchorEl}
      arrow
      placement={placement}
      sx={{ zIndex: 1300 }}
      className={twMerge(`flex relative`, closable ? 'pr-9' : '', className)}
      {...getTestIdProps(id)}
    >
      <ClickAwayListener onClickAway={() => onClose?.()}>
        <div>
          {closable ? (
            <IconButton
              aria-label={`close ${id} pop over`}
              className='absolute top-3.5 right-1 w-7 h-7'
              onClick={onClose}
              data-testid={`${id}-popover-close-button`}
            >
              <Icon name='Close' className='w-5 h-5 text-white' />
            </IconButton>
          ) : null}
          {content}
        </div>
      </ClickAwayListener>
    </PopperComponent>
  )
}
