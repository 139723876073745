import { cloudPlatformLoginUrls, urlParams } from '@training/constants'

/**
 * This function is used to determine the redirect url for the cloud platform login
 * based on the current flexai url
 * @returns {string} - the redirect url for the cloud platform login
 * by default it will return the first url in the cloudPlatformLoginUrls array
 */
export const getRedirectUrl = () => {
  const currentCloudPlatformLoginUrl = cloudPlatformLoginUrls.find(
    (url) => url.flexaiUrl === window.location.hostname
  )
  if (currentCloudPlatformLoginUrl) {
    return currentCloudPlatformLoginUrl.cloudUrl
  }
  return cloudPlatformLoginUrls[0].cloudUrl
}

export const formatRedirectUrl = (url: string, orgId?: string) => {
  const redirectUrl = new URL(window.location.href)

  if (orgId) {
    redirectUrl.searchParams.set(urlParams.ORG_ID, orgId)
    url.concat(`?redirect_app_url=${redirectUrl.toString()}`)
  }

  return url
}
